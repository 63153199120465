import $ from 'jquery'
import jquery from 'jquery';
import { Foundation } from 'foundation-sites';
import './application.scss'

window.jQuery = jquery;
window.$ = jquery;
Foundation.addToJquery($);

jQuery(document).ready($ => ($(document).foundation()));




